import React, { useEffect, useState, useMemo, useCallback } from "react";
import moment from "moment";
import Breadcrumb from "../../../../component/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { exportReportRequestForm } from "../../../../redux/feature/translation/export/exportReportFormSlice";
import errorToast from "../../../../component/utils/errorToast";
import Loader from "../../../../component/Common/Loader";

export const TranslationExportFile = () => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const currentTime = useMemo(() => new Date(), []);
  const { name: userName, username: userEmail } = useMemo(
    () => auth.userInfo,
    [auth.userInfo]
  );
  const confirmationMessage = useMemo(
    () =>
      "You will receive an e-mail confirmation when the Translation Export File is ready.",
    []
  );
  const errorMessage = useMemo(
    () => "There is an error in the Translation Export File.",
    []
  );

  const [time, setTime] = useState(currentTime);
  const [author, setAuthor] = useState(userName || "");
  const [type, setType] = useState("Export Report");
  const [email, setEmail] = useState(userEmail || "");
  const responseExportReport = useSelector((state) => state.exportReportRequestForm);
  useEffect(() => {
    if (responseExportReport.isSuccess) {
      console.log("Translation Export File successful ==>");
    } else if (responseExportReport.isError) {
      errorToast("Translation Export File failed");
    }
  }, [responseExportReport.isSuccess]);
  const handleSubmit = useCallback(() => {
    if (!time) {
      errorToast("Time is a Mandatory field");
    } else if (!author) {
      errorToast("Author is a Mandatory field");
    } else if (!type) {
      errorToast("Type is a Mandatory field");
    } else if (!email) {
      errorToast("Email is a Mandatory field");
    } else {
      const translationExportFileRequest = {
        author: author,
        type: type,
        email_id: email,
        timestamp: time.toISOString(),
      };
      dispatch(exportReportRequestForm(translationExportFileRequest));
    }
  }, [author, email, time, type, dispatch]);

  const renderContent = () => {
    if (responseExportReport.loading) {
      return <Loader />;
    } else if (responseExportReport.isSuccess) {
      return (
        <div className="pb-2 text-teal">
          <p>
            Request ID :{" "}
            {responseExportReport.data.length > 0
              ? responseExportReport.data[responseExportReport.data.length - 1]
                  .requestId
              : ""}
          </p>
          <p>{confirmationMessage}</p>
        </div>
      );
    } else if (responseExportReport.isError) {
      return <div className="pb-2 text-teal">{errorMessage}</div>;
    } else {
      return <div className="pb-2 text-teal">{confirmationMessage}</div>;
    }
  };

  return (
    <div className="dashboard-ecommerce">
      <div className="container-fluid dashboard-content">
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <Breadcrumb />
            <div className="widget">
              <div className="card-header">Translation Export File</div>
              <hr className="hr" />
              <div className="px-3 py-2">
                <div>
                To request a Translation Export File, click submit. This action generates the JSON file to be sent to the translation, the file lists the entries that will be updated with translations. Entries that meet the following criteria will be included: 
                </div>
                <ul>
                  <li>
                    <b>Published status </b>
                  </li>
                  <li>
                    <b>Not flagged “never translate” </b>
                  </li>
                  <li><b>Last updated by is NOT “Translation Integration” </b></li>
                </ul>
                <div>
                    Note: This action does not update the entries in Contentful. To update the entries in Contentful to “in translation,” you run the Translation Job and select the Export File from the drop down.
                </div>
              </div>
              <div className="container">
                <div className="row align-items-center my-4">
                  <div className="text-black col-xl-2 col-lg-6 col-md-6 col-sm-12 col-12">
                    User Name
                  </div>
                  <input
                    className="col-xl-5 col-lg-6 col-md-6 col-sm-12 col-12 px-2 py-1"
                    value={auth.userInfo?.name}
                    disabled
                  />
                </div>
                <div className="row align-items-center my-2">
                  <div className="text-black col-xl-2 col-lg-6 col-md-6 col-sm-12 col-12">
                    Date & Time
                  </div>
                  <input
                    className="col-xl-5 col-lg-6 col-md-6 col-sm-12 col-12 px-2 py-1"
                    value={moment(currentTime).format("MM/DD/YYYY hh:mm a")}
                    disabled
                  />
                </div>
                <div className="pb-2 pt-2">
                  <div className="row">
                    <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12" />
                    <button
                      onClick={handleSubmit}
                      className="submitButton col-xl-2 col-lg-6 col-md-6 col-sm-12 col-12"
                      type="button"
                    >
                      Submit
                    </button>
                  </div>
                </div>
                {renderContent()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
