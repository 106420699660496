import React, { useEffect, useState, useMemo } from "react";
import moment from "moment";
import Breadcrumb from "../../../../component/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { ReportData } from "../../../../redux/feature/translation/report/ReportDataSlice";
import { exportUpdateRequestForm } from "../../../../redux/feature/translation/export/exportUpdateFormSlice";
import Loader from "../../../../component/Common/Loader";

export const TranslationJob = () => {
  const auth = useSelector((state) => state.auth);
  const { name: userName, username: userEmail } = useMemo(
    () => auth.userInfo,
    [auth.userInfo]
  );
  const currentTime = new Date();
  const [time, setTime] = useState(currentTime);
  const [author, setAuthor] = useState(userName || "");
  const [type, setType] = useState("full export");
  const [email, setEmail] = useState(userEmail || "");
  const responseFullExport = useSelector((state) => state.exportUpdateRequestForm);
  const confirmationMessage = useMemo(
    () =>
      "You will receive an email confirmation when the Translation Job is complete.",
    []
  );
  const errorMessage = useMemo(
    () => "There is an error in the Translation Job.",
    []
  );
  const responseExportReport = useSelector((state) => state.ReportData);
  const dispatch = useDispatch();

  const [sampleData, setSampleData] = useState([]);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [selectedRequestId, setSelectedRequestId] = useState("");
  const [isLoading, setIsLoading] = useState(true); // Track loading state
  const [isSubmitting, setIsSubmitting] = useState(false); // Track submission state

  useEffect(() => {
    const request = {
      type: "exportReport",
      download: false,
    };
    dispatch(ReportData(request));
  }, [dispatch]);

  useEffect(() => {
    if (responseExportReport.data && responseExportReport.data.length) {
      const data =
        responseExportReport.data[0]?.value?.map((obj) => ({
          requestId: obj.request_id,
          timestamp: obj.timestamp,
          author: obj.author,
          email: obj.email_id,
        })) || [];

      setSampleData(data);

      const formattedOptions = data.map((obj) => {
        const formattedTimestamp = moment(obj.timestamp).format(
          "DD/MMM/YYYY hh:mm A"
        );
        return {
          value: obj.requestId,
          label: `Request Id - ${obj.requestId}, ${obj.author}, ${formattedTimestamp}`,
        };
      });

      setDropdownOptions(formattedOptions);
      setIsLoading(false);
    }
  }, [responseExportReport]);

  const handleSubmit = () => {
    if (selectedRequestId) {
      setIsSubmitting(true); // Show loader while submitting
      const full_export_request = {
        author: author,
        type: type,
        email_id: email,
        timestamp: time.toISOString(),
        selectedRequestId: selectedRequestId,
      };
      console.log("selectedRequestId", full_export_request);
      dispatch(exportUpdateRequestForm(full_export_request));
    }
  };

  const renderContent = () => {
    if (responseFullExport.loading) {
      return <Loader />;
    } else if (responseFullExport.isSuccess) {
      return (
        <div className="pb-2 text-teal">
          <p>
            Request ID :{" "}
            {responseFullExport.data.length > 0
              ? responseFullExport.data[responseFullExport.data.length - 1]
                  .requestId
              : ""}
          </p>
          <p>{confirmationMessage}</p>
        </div>
      );
    } else if (responseFullExport.isError) {
      return <div className="pb-2 text-teal">{errorMessage}</div>;
    } else {
      return <div className="pb-2 text-teal">{confirmationMessage}</div>;
    }
  };

  return (
    <div className="dashboard-ecommerce">
      <div className="container-fluid dashboard-content">
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <Breadcrumb />
            <div className="widget">
              <div className="card-header">Export Job Request</div>
              <hr className="hr" />
              <div className="px-3 py-2">
                <div>
                Select the Translation Export File that you want to run and click submit. This action will update the entries in Contentful to “in translation,” show last updated by “translation integration,” and place the entries in a locked status so that editors cannot make changes during the translation update process.
                </div>
              </div>
              <div className="container">
                <div className="row align-items-center my-4">
                  <div className="text-black col-xl-2 col-lg-6 col-md-6 col-sm-12 col-12">
                    User Name
                  </div>
                  <input
                    className="col-xl-5 col-lg-6 col-md-6 col-sm-12 col-12 px-2 py-1"
                    value={auth.userInfo?.name}
                    disabled
                  />
                </div>
                <div className="row align-items-center my-4">
                  <div className="text-black col-xl-2 col-lg-6 col-md-6 col-sm-12 col-12">
                    Date & Time
                  </div>
                  <input
                    className="col-xl-5 col-lg-6 col-md-6 col-sm-12 col-12 px-2 py-1"
                    value={moment(currentTime).format("MM/DD/YYYY hh:mm a")}
                    disabled
                  />
                </div>
                <div className="row align-items-center my-4">
                  <div className="text-black col-xl-2 col-lg-6 col-md-6 col-sm-12 col-12">
                    Select Request ID
                  </div>
                  <select
                    className="col-xl-5 col-lg-6 col-md-6 col-sm-12 col-12 px-2 py-1"
                    value={selectedRequestId}
                    onChange={(e) => setSelectedRequestId(e.target.value)}
                    disabled={isLoading}
                  >
                    <option value="" disabled>
                      {isLoading ? "Loading..." : "Select a request id"}
                    </option>
                    {dropdownOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="pb-2 pt-2">
                  <div className="row">
                    <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12" />
                    <button
                      className="submitButton col-xl-2 col-lg-6 col-md-6 col-sm-12 col-12"
                      type="button"
                      onClick={handleSubmit}
                      disabled={!selectedRequestId || isSubmitting} // Disable button when no request ID is selected or during submission
                    >
                      Submit
                    </button>
                  </div>
                </div>
                {renderContent()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};