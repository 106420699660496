import { Link } from "react-router-dom";
import Breadcrumb from "../../../component/Breadcrumb";
import { MdKeyboardArrowRight } from "react-icons/md";
import translation from "../../../assets/images/translation.png";

export const TranslationRequestForm = () => {
  return (
    <div className="dashboard-ecommerce">
      <div className="container-fluid dashboard-content">
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <Breadcrumb />
            <div className="widget">
              <div className="card-header">Translations Request Form</div>
              <hr className="hr" />
              <div className="row">
                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                  <div className="card">
                    <div className="card-body-category">
                      <img
                        src={translation}
                        alt="user_card"
                        width="50"
                        height="50"
                      />
                      <h5 className="text-muted">Context File</h5>
                      <p className="card-text">
                        Request a context file to capture current intranet site
                        structure, including pages and their entries.
                      </p>
                      <Link
                        to="/translations/requestForm/context-file"
                        className="card-link"
                      >
                        View More
                        <span>
                          <MdKeyboardArrowRight size={20} color="black" />
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                  <div className="card">
                    <div className="card-body-category">
                      <img
                        src={translation}
                        alt="user_card"
                        width="50"
                        height="50"
                      />
                      <h5 className="text-muted">Translation Export File</h5>
                      <p className="card-text">
                        Request the JSON file to send to the translation vendor;
                        the report includes a list of the content entries that
                        are ready to be updated with translations..
                      </p>
                      <Link
                        to="/translations/requestForm/Translation-Export-File"
                        className="card-link"
                      >
                        View More
                        <span>
                          <MdKeyboardArrowRight size={20} color="black" />
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                  <div className="card">
                    <div className="card-body-category">
                      <img
                        src={translation}
                        alt="user_card"
                        width="50"
                        height="50"
                      />
                      <h5 className="text-muted">Translation Job</h5>
                      <p className="card-text">
                        Run the translation job in Contentful to flag and lock
                        the entries that will be updated with translations.
                      </p>
                      <Link
                        to="/translations/requestForm/Translation-Job"
                        className="card-link"
                      >
                        View More
                        <span>
                          <MdKeyboardArrowRight size={20} color="black" />
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                  <div className="card">
                    <div className="card-body-category">
                      <img
                        src={translation}
                        alt="user_card"
                        width="50"
                        height="50"
                      />
                      <h5 className="text-muted">Translation Import</h5>
                      <p className="card-text">
                        Upload the translated file from the vendor. 
                      </p>
                      <Link
                        to="/translations/requestForm/import-job-request"
                        className="card-link"
                      >
                        View More
                        <span>
                          <MdKeyboardArrowRight size={20} color="black" />
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                  <div className="card">
                    <div className="card-body-category">
                      <img
                        src={translation}
                        alt="user_card"
                        width="50"
                        height="50"
                      />
                      <h5 className="text-muted">
                        Export-Import Reconciliation Request 
                      </h5>
                      <p className="card-text">
                        Submit Request to perform Reconciliation on Translation
                        Entries
                      </p>
                      <Link to="/" className="card-link">
                        View More
                        <span>
                          <MdKeyboardArrowRight size={20} color="black" />
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
